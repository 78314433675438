export const TeamTypeEnum = {
    ADMIN_TEAM: 'நிர்வாக குழு',
    SUBCOMMITTEE_MEMBERS: 'செயற்குழு உறுப்பினர்கள்',
};


export const GenderEnum ={
    MALE: "Male",
    FEMALE: "Female"
  };
  
export const MemberTypeEnum ={
    DIRECTORS: "இயக்குனர்கள் / Directors",
    ADVISORS: "ஆலோசகர்கள் / Advisors",
    LIFETIME_MEMBERSHIP: "ஆயுட்கால உறுப்பினர்கள் / Lifetime membership",
    MEMBERSHIP: "உறுப்பினர்கள் / Membership"
  };