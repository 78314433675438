import React from "react";
import Home from './home/home';
import Donate from './Donate/donate';
import News from './news/news';
import EventPage from "./Event/eventpage";
import Contact from "./contact/contactform";
import Structurevision from "./Multiple/structure/structurevision";



const Main = () => {
    return(
        <>
        
        <Home/>
       <Structurevision/>
        <News/>
        <EventPage/>
        <Donate/>
        <Contact/>
        
    </>
    );
}
export default Main;