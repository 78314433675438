import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../service/apiservice';
import { useTranslation } from 'react-i18next';
import { Allapi } from '../constant/constantApi';
import Scroll from './scroll';

const Event = () => {
  const [eventsItems, setEventsItems] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventsData = async (lang) => {
      try {
        const response = await get(`${Allapi.FESTIVAL_getapi}?lang=${lang}`);
        setEventsItems(response.data.data);
      } catch (error) {
        console.error('Failed to fetch events data:', error);
      }
    };

    fetchEventsData(i18n.language);
  }, [i18n.language]);

  const handleGallery = (event) => {
    navigate('/galleryhome', { state: { eventsData: event } });
  };

  return (
    <div className="relative z-1 px-4 md:px-16 py-4 md:py-16 bg-[#F9FAFB]">
      <div className="grid grid-cols-1 md:grid-cols-10 gap-4 justify-center">
        <div className="col-span-1 md:col-span-3 mt-10 mb-10  event-text:pl-[50px]">
          <div className="sticky top-20">
            <div className='pt-8'>
              <div className="inline-block text-3xl  mb-4 purpose-text:text-5xl">
                {t('festival.title')}:
              </div>
            </div>
            <p className="text-black text-xl md:text-1xl mb-6 mt-4 event-text:text-4xl event-text:leading-loose leading-7 md:leading-9">
              {t('festival.description')}
            </p>
            <Link to="/membership">
              <button className="bg-blue-950 text-white w-full lg:w-64 py-4 rounded-full font-semibold text-sm">
                {t('festival.membershipRegistration')} <i className="fa fa-arrow-right text-white pl-2" aria-hidden="true"></i>
              </button>
            </Link>
            <Link to="/events" className="block text-black-300 font-bold text-lg mt-10">
              {t('festival.allevents')}<i className="fa fa-arrow-right text-[#281E5D] pl-2" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
        <div className="col-span-1 md:col-span-7 md:mt-16 sm:pl-20 mb-5">
          
            {/* <h1 className="text-[25px] sm:text-[40px] mb-2 mt-4 text-black-900 event-text:text-5xl font-bold">
              {t('festival.heading1')}<br /> {t('festival.heading2')}
            </h1> */}
          <Scroll/>
        </div>
      </div>
    </div>
  );
};

export default Event;
