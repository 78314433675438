import React from "react";
import Video from "./video";
// import EventDetails from "./EventDetails"
import Donate from "../../Donate/donate";
import Contact from "../../contact/contactform";
import Gallery from "./gallery";
import Header from "../../header/header";


const GalleryHome = () => {
    return(
        <>
        <Header/>
        <Video/>
        {/* <EventDetails/> */}
        <Gallery/>
        <Donate/>
       <Contact/>
</>
    );
}
export default GalleryHome;