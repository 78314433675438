// import React,{useEffect} from 'react';
// import { Link } from 'react-router-dom';
// import { FaArrowRight } from "react-icons/fa";
// import bannerImage from '../structure/images/banner.png';
// import Header from '../../header/header.js';
// import { useTranslation } from 'react-i18next';


// const StructureBanner = () => {
//   const { t } = useTranslation();

 

//   const headings = [
//     { text: t('structure.title1'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px]  big-screen:leading-loose head-home:text-[400%] text-3xl lg:tracking-wider big-screen:text-[110px] xl:text-[330%]  lg:leading-snug sm:text-6xl lg:text-[350%]  font-bold' },
//     { text: t('structure.committe'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[400%] lg:tracking-wider  big-screen:text-[110px] lg:text-[350%] xl:text-[330%] border-b-2 big-screen:border-b-4 font-bold border-[#FFFFFF] big-screen:pb-24 pb-4 md:pb-14 iphonexr:pb-8' }
//   ];

//   const buttonLinks = [
//     { text: t('structure.membership'), className: 'rounded-full  bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership' },
//     { text: t('structure.contact'), className: 'flex items-center font-base  big-screen:text-3xl text-white-900', to: '/contactus' }
//   ];

//   const messages = [
    
//     [t('structure.bannertitle1'), t('structure.bannertitle2'), t('structure.bannertitle3'),]
//   ];



//   return (
//     <div id='home' className="relative w-[100%] min-h-screen bg-fixed bg-cover item-center place-items-center text-white " style={{ backgroundImage: `url(${bannerImage}) ` }}>
//      <Header/>
//       <div className="small-screen:pt-96 medium-screen:mx-32 flex flex-col xl:pt-[20%] pt-40  big-screen:pt-[28rem] big-screen:mx-32
//        head-home:gap-32 xl:gap-[10%] head-home:mx-20 very-screen:pt-[21%]   medium-screen:pt-[19%] head-home:pt-[21%] big-screen:gap-44 mx-4 lg:mx-14 md:pt-44 md:flex-row  gap-20">
//         <div>
//           <div data-aos="fade-right" data-aos-delay="30" data-aos-duration="2000">
//             <div className="flex flex-col  text-start text-[#F9FAFB] iphonexr:gap-5">
//               {headings.map((heading, index) => (
//                 <span key={index} className={heading.className}>
//                   {heading.text}
//                 </span>
//               ))}
//             </div>
//             </div>
          

//           <div className='mt-8 md:mt-14 big-screen:mb-12 big-screen:mt-28 flex flex-col  big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center space-x-0 md:space-x-4 iphonexr:pt-14 iphonexr:gap-12'>
//             {buttonLinks.map((link, index) => (
//               <Link key={index} to={link.to} className={link.className}>
//                 {link.text}
//                 {link.to === '/contactus' && (
//                   <i className='ml-2 font-light'>
//                     <FaArrowRight />
//                   </i>
//                 )}
//               </Link>
//             ))}
            
//           </div>

          
//         </div>
//         {/* <div className=" head-home:text-xl small-screen:text-3xl medium-screen:text-3xl big-screen:mt-12  lg:font-light lg:text-xl   text-start">
//           {messages.map((paragraph, index) => (
//             <div key={index} className="flex flex-col head-home:leading-loose xl:leading-loose big-screen:leading-loose medium-screen:leading-loose big-screen:text-4xl mb-6 iphonexr:gap-10 ">
//               {paragraph.map((line, lineIndex) => (
//                 <span key={lineIndex}>{line}</span>
//               ))}
//             </div>
//           ))}
//         </div> */}
//       </div>

//     </div>
//   );
// };

// export default StructureBanner;
import React from 'react';
import bannerImage from '../vision/images/visionbanner.png';
import Header from '../../header/header';
import { useTranslation } from 'react-i18next';


const MembersBanner = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className="relative h-[400px] bg-cover bg-center bg-fixed" style={{ backgroundImage: `url(${bannerImage}) `}}>
      <Header/>
      <div className="absolute inset-0 bg-black opacity-0"></div>
      <div className="relative flex items-center justify-center h-full">
        <div className="text-center text-white px-4">
          <h1 className="text-4xl md:text-5xl font-bold">{t('structure.title1')}</h1>
          <h1 className="sm:mt-4 mt-0 text-4xl md:text-5xl font-bold">{t('structure.committe')}</h1>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default MembersBanner;